// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-services-animal-health-plans-js": () => import("./../../../src/pages/services/animal-health-plans.js" /* webpackChunkName: "component---src-pages-services-animal-health-plans-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-lifestyle-block-package-js": () => import("./../../../src/pages/services/lifestyle-block-package.js" /* webpackChunkName: "component---src-pages-services-lifestyle-block-package-js" */),
  "component---src-pages-services-nait-compliance-js": () => import("./../../../src/pages/services/nait-compliance.js" /* webpackChunkName: "component---src-pages-services-nait-compliance-js" */),
  "component---src-pages-services-preventative-health-services-js": () => import("./../../../src/pages/services/preventative-health-services.js" /* webpackChunkName: "component---src-pages-services-preventative-health-services-js" */)
}

